const handleJson = (payload) => payload && payload.ok && payload.json();

const headerForCommon = {
  headers: {
    "Content-Type": "Application/json; charset=UTF-8",
  },
  credentials: JSON.parse(process.env.REACT_APP_MOCK) ? "omit" : "include",
  cache: "no-cache",
  mode: "cors",
};

const headerForGet = { ...headerForCommon, method: "GET" };
const headerForDel = { ...headerForCommon, method: "DELETE" };

export const list = (endpoint, page) =>
  fetch(`${endpoint}?page=${page}`, headerForGet)
    .then(handleJson)
    .catch((e) => Promise.resolve(null));

export const post = (endpoint, notificationId) => list(`${endpoint}/${notificationId}`);

export const remove = (endpoint, notificationId) =>
  fetch(`${endpoint}/${notificationId}`, headerForDel)
    .then((payload) => payload && payload.status)
    .catch((e) => Promise.resolve(null));
