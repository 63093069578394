import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import classNames from "classnames";
import "./PageNav.scss";

class PageNav extends Component {
  render() {
    const { currentPage, pageSize, totalCount } = this.props.page;
    const pages = [];

    for (let index = 1; index <= Math.ceil(totalCount / pageSize); index++) {
      pages.push(
        <div key={index} className={classNames("page", { current: currentPage === index })}>
          <NavLink to={`/${index}`}>{`${index}`}</NavLink>
        </div>,
      );
    }

    return pages.length > 1 && <div className={"page-nav"}>{pages}</div>;
  }
}

export default withRouter(PageNav);
