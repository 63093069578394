import React, { Component } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import moment from "moment";
import "./PostDetail.scss";

class PostDetail extends Component {
  render() {
    const { title, createTimestamp, content } = this.props.post;
    const dateInMMDD = moment(createTimestamp).format("MM/DD");

    return (
      <div className={"post-detail"}>
        <div className={"mobile-header"}>
          <div className={"mobile-title"}>{title}</div>
          <div className={"mobile-datetime"}>{dateInMMDD}</div>
        </div>

        <Markdown remarkPlugins={[remarkGfm]}>{content}</Markdown>
      </div>
    );
  }
}

export default PostDetail;
