import React, { Component } from "react";
import PostList from "components/PostList";
import PageNav from "components/PageNav";
import { list, post, remove } from "api/notification";
import "./NotiPage.scss";

class NotiPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
      pagination: {
        totalCount: 0,
        pageSize: 10,
      },
    };
  }

  reload() {
    const { totalCount, pageSize } = this.state.pagination;
    let { page = 1 } = this.params(this.props);

    // reload the previous page when current page is empty
    if (this.state.list.length === 0 && page !== 1 && page === Math.ceil(totalCount / pageSize)) {
      page = page - 1;
    }

    list(this.props.endpoint, page).then((payload) => payload && this.setState(payload));
  }

  removeFromList(notificationId) {
    return remove(this.props.endpoint, notificationId);
  }

  componentDidMount() {
    this.reload();
  }

  params(props) {
    return (props.match && props.match.params) || {};
  }

  componentDidUpdate(prevProps) {
    const { page: oldPage = 1 } = this.params(prevProps);
    const { page: newPage = 1 } = this.params(this.props);

    oldPage !== newPage && this.reload();
  }

  requestBody(notificationId) {
    return post(this.props.endpoint, notificationId).then((content) => {
      const updatedList = this.state.list.map((item) =>
        item.notificationId === notificationId ? { ...content, isRead: true } : item,
      );
      this.setState({ list: updatedList });
      return content;
    });
  }

  render() {
    return (
      <div className={"noti-page"}>
        <PostList
          list={this.state.list}
          content={(notificationId) => this.requestBody(notificationId)}
          remover={this.removeFromList.bind(this)}
          reload={() => this.reload()}
        />
        {this.state.pagination && <PageNav page={this.state.pagination} />}
        {process.env.REACT_APP_VERSION && (
          <div className="webapp-version">version: {process.env.REACT_APP_VERSION}</div>
        )}
      </div>
    );
  }
}

export default NotiPage;
