import React, { Component } from "react";
import classNames from "classnames";
import moment from "moment";
import { games } from "constants/game";
import PostDetail from "components/PostDetail";
import "./PostList.scss";

const Selector = ({ notify, selected }) => {
  const handleClick = () => notify && notify(!selected);

  return <div className={classNames("selector", { selected })} onClick={handleClick}></div>;
};

const DeleteButton = ({ click }) => (
  <div className={"button"} onClick={() => click()}>
    선택 삭제
  </div>
);

class PostList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: "",
      enableDeletion: false,
    };
    this.selected = -1;
    this.candidates = {};
  }

  handleDetail(post) {
    if (this.selected === post.notificationId) {
      this.selected = -1;
      this.setState({ content: "" });
    } else {
      this.selected = post.notificationId;
      this.props
        .content(this.selected)
        .then((postWithContent) =>
          this.setState({ content: postWithContent ? postWithContent.content : "존재하지 않는 쪽지입니다." }),
        );
    }
  }

  selectForDelete({ notificationId }) {
    if (this.candidates[notificationId]) {
      this.candidates[notificationId] = false;
    } else {
      Object.assign(this.candidates, JSON.parse(`{ "${notificationId}": true }`));
    }

    const hasCandidates = Object.entries(this.candidates).filter(([_notificationId, selected]) => selected).length > 0;
    this.setState({ enableDeletion: hasCandidates });
  }

  selectAllDelete() {
    this.props.list.map((post) => this.selectForDelete(post));
  }

  handleDelete() {
    if (window.confirm("선택된 쪽지를 삭제하시겠습니까")) {
      Promise.all(
        Object.entries(this.candidates)
          .filter(([notificationId, selected]) => selected)
          .map(([notificationId]) => this.props.remover(notificationId)),
      )
        .then(() => this.setState({ enableDeletion: false }))
        .then(() => (this.candidates = {}))
        .then(() => this.props.reload());
    }
  }

  render() {
    const isSelectedPost = (postId) => this.selected === postId;
    const listPosts = (list) =>
      list.map((post, index) => (
        <div key={index}>
          <div className={classNames("row", { unread: !post.isRead, current: isSelectedPost(post.notificationId) })}>
            <div className={"selection"}>
              <Selector notify={() => this.selectForDelete(post)} selected={this.candidates[post.notificationId]} />
            </div>
            <div className={"game"}> {games[post.game]} </div>
            <div className={"title"}>
              <span onClick={() => this.handleDetail(post)}>{post.title}</span>
            </div>
            <div className={"datetime"}> {moment(post.createTimestamp).format("YYYY-MM-DD HH:mm")} </div>
          </div>
          <div className={"body"}>
            {isSelectedPost(post.notificationId) && <PostDetail post={{ ...post, content: this.state.content }} />}
          </div>
        </div>
      ));

    return (
      <div className={"post-list"}>
        {this.state.enableDeletion && (
          <div className={"control"}>
            <DeleteButton click={() => this.handleDelete()} />
          </div>
        )}
        <div className={"header row"}>
          <div className={"selection"}>
            <Selector notify={this.selectAllDelete.bind(this)} />
          </div>
          <div className={"game"}>게임</div>
          <div className={"title"}>제목</div>
          <div className={"datetime"}>날짜</div>
        </div>
        <div className={"list"}>
          {this.props.list && this.props.list.length ? (
            listPosts(this.props.list)
          ) : (
            <div className={"none"}>쪽지가 없습니다.</div>
          )}
        </div>
      </div>
    );
  }
}

export default PostList;
