import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NotiPage from "components/NotiPage";
import "./App.scss";

function App() {
  return (
    <div className="App">
      <div className="App-container">
        <Router>
          <Switch>
            <Route
              exact
              path={"/"}
              render={(props) => <NotiPage endpoint={process.env.REACT_APP_ENDPOINT} {...props} />}
            />
            <Route
              path={"/:page"}
              render={(props) => <NotiPage endpoint={process.env.REACT_APP_ENDPOINT} {...props} />}
            />
          </Switch>
        </Router>
      </div>
    </div>
  );
}

export default App;
